import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import gsap from 'gsap'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Modal from '../Modal/Modal'
import OpenModal from '../Modal/OpenModal'
// import styles from './Projects.module.scss'

export default ({
  styles,
  slug,
  hero,
  title,
  description,
  role,
  url,
  content,
}) => {
  const [isModal, setIsModal] = useState(false)

  const tl = gsap.timeline()
  // gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    if (isModal) {
      tl.from('.modal__inner', {
        x: '-100%',
        duration: 0.8,
        ease: 'power1.inOut',
      }).from('.itemFadein', {
        opacity: 0,
        y: '30',
        duration: 0.6,
        ease: 'power1.inOut',
        stagger: {
          amount: '0.8',
        },
      })
    }
  })

  return (
    <>
      <OpenModal setIsModal={setIsModal} slug={slug} styles={styles}>
        <Img
          fluid={hero}
          alt={title}
          className={`fadein ${styles.item__image}`}
        />
        <h3 className={`fadein font-serif ${styles.item__heading}`}>{title}</h3>
        <div className={`fadein ${styles.item__description}`}>
          {description}
        </div>
      </OpenModal>

      {isModal && (
        <Modal
          setIsModal={setIsModal}
          slug={slug}
          title={title}
          role={role}
          url={url}
          content={content}
        />
      )}
    </>
  )
}
