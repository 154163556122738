import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import gsap from 'gsap'

import styles from './Hero.module.scss'

export default () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "hero-main.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  useEffect(() => {
    // prevents initial flashing
    gsap.to('.hero', { css: { visibility: 'visible' }, duration: 0 })

    // timeline
    const tl = gsap.timeline()

    // timeline animation
    tl.from('.tl-text', {
      duration: 1.6,
      opacity: 0,
      y: 70,
      ease: 'Power4.easeOut',
      delay: 0.5,
      skewY: 7,
      stagger: {
        amount: 0.3,
      },
    })
      .to('.tl-overlay', {
        duration: 1.4,
        height: 0,
        ease: 'expo.easeInOut',
        delay: -1,
        stagger: 0.4,
      })
      .to('.tl-cover', {
        duration: 2.2,
        scaleX: 0,
        transformOrigin: 'right',
        ease: 'expo.easeInOut',
        delay: -0.5,
      })
      .from('.tl-line', {
        duration: 1.4,
        scaleX: 0,
        transformOrigin: 'left',
        ease: 'expo.inOut',
        delay: -2.4,
        stagger: {
          amount: 0.3,
        },
      })
      .to('.header', {
        opacity: 1,
        delay: -0.4,
      })
      .to('.tl-scroll', {
        opacity: 1,
      })
  }, [])

  return (
    <div className={`hero intersection ${styles.hero}`}>
      <div className={`${styles.copy}`}>
        <div className={styles.copy__inner}>
          <div className={styles.copy__item}>
            <span className="tl-text">Hello.</span>
          </div>
          <div className={styles.copy__item}>
            <span className="tl-text">I design and build digital products</span>
          </div>
          <div className={styles.copy__item}>
            <span className="tl-text">that are functional and effective.</span>
          </div>
        </div>
      </div>

      <div className={styles.overlay}>
        <div className={`tl-overlay ${styles.overlay__item}`}></div>
        <div className={`tl-overlay ${styles.overlay__item}`}></div>
        <div className={`tl-overlay ${styles.overlay__item}`}></div>
      </div>

      <div className={`tl-bg ${styles.bg}`}>
        <div className={styles.bg__inner}>
          <Img
            fluid={data.heroImage.childImageSharp.fluid}
            alt="Ayana Osawa"
            className={styles.bg__image}
          />
          <div className={`tl-cover ${styles.bg__cover}`}></div>
        </div>
      </div>

      <div className={`${styles.line}`}>
        <div className={`${styles.line__inner}`}>
          <span className="tl-line"></span>
          <span className="tl-line"></span>
          <span className="tl-line"></span>
        </div>
      </div>

      <div className={`tl-scroll ${styles.scroll}`}>
        <div className={`${styles.scroll__inner}`}>
          <span className={styles.scroll__line}></span>
          <span className={styles.scroll__line}></span>
          <span className={styles.scroll__text}>SCROLL</span>
        </div>
      </div>
    </div>
  )
}
