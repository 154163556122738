import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import ProjectItem from './ProjectItem'
import styles from './Projects.module.scss'
import styled from 'styled-components'

const StyledModalContent = styled.div`
  .heading {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    line-height: 1.2;
    @media (min-width: 668px) {
      font-size: 1.7rem;
    }
  }
  .lead {
    margin-bottom: 5rem;
  }
  .text {
    font-size: 0.95rem;
    @media (min-width: 668px) {
      font-size: 1rem;
    }
  }
  .image__main {
    max-height: 600px;
  }
  .image__sub {
    max-height: 550px;
  }
  .image_initial {
    max-height: initial;
  }
  .block {
    margin-bottom: 4rem;
    &:last-child {
      margin-bottom: 0;
    }
    @media (min-width: 668px) {
      margin-bottom: 8rem;
    }
  }
  p {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query {
      imageMisawayaMain: file(
        relativePath: { eq: "projects-misawaya-main.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMisawayaSub1: file(
        relativePath: { eq: "projects-misawaya-sub1.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMisawayaSub2: file(
        relativePath: { eq: "projects-misawaya-sub2.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMisawayaSub3: file(
        relativePath: { eq: "projects-misawaya-sub3.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEYMain: file(relativePath: { eq: "projects-ey-main.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEYSub: file(relativePath: { eq: "projects-ey-sub.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageCinericMain: file(
        relativePath: { eq: "projects-cineric-main.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageCinericSub: file(relativePath: { eq: "projects-cineric-sub.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNamingMain: file(relativePath: { eq: "projects-naming-main.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNamingSub: file(relativePath: { eq: "projects-naming-sub.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMorusMain: file(relativePath: { eq: "projects-morus-main.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageMorusSub: file(relativePath: { eq: "projects-morus-sub.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageParallax: file(relativePath: { eq: "projects-parallax.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageCineric: file(relativePath: { eq: "projects-cineric.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFather: file(relativePath: { eq: "projects-father.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTempura: file(relativePath: { eq: "projects-tempura.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageGRF: file(relativePath: { eq: "projects-food.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageCinericJP: file(relativePath: { eq: "projects-cinericjp.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const imageMisawayaMain = data.imageMisawayaMain.childImageSharp.fluid
  const imageMisawayaSub1 = data.imageMisawayaSub1.childImageSharp.fluid
  const imageMisawayaSub2 = data.imageMisawayaSub2.childImageSharp.fluid
  const imageMisawayaSub3 = data.imageMisawayaSub3.childImageSharp.fluid
  const imageEYMain = data.imageEYMain.childImageSharp.fluid
  const imageEYSub = data.imageEYSub.childImageSharp.fluid
  // const imageCinericMain = data.imageCinericMain.childImageSharp.fluid
  // const imageCinericSub = data.imageCinericSub.childImageSharp.fluid
  const imageNamingMain = data.imageNamingMain.childImageSharp.fluid
  const imageNamingSub = data.imageNamingSub.childImageSharp.fluid
  // const imageMorusMain = data.imageMorusMain.childImageSharp.fluid
  // const imageMorusSub = data.imageMorusSub.childImageSharp.fluid
  // const imageParallax = data.imageParallax.childImageSharp.fluid
  // const imageCineric = data.imageCineric.childImageSharp.fluid
  // const imageFather = data.imageFather.childImageSharp.fluid
  // const imageTempura = data.imageTempura.childImageSharp.fluid
  // const imageGRF = data.imageGRF.childImageSharp.fluid
  // const imageCinericJP = data.imageCinericJP.childImageSharp.fluid

  // useEffect(() => {
  //   const mediaHineru = document.querySelector('.mediaHineru')
  //   mediaHineru.addEventListener('mouseover', () => {
  //     mediaHineru.play()
  //   })
  //   mediaHineru.addEventListener('mouseout', () => {
  //     mediaHineru.load()
  //   })
  //   const mediaFS = document.querySelector('.mediaFS')
  //   mediaFS.addEventListener('mouseover', () => {
  //     mediaFS.play()
  //   })
  //   mediaFS.addEventListener('mouseout', () => {
  //     mediaFS.load()
  //   })
  //   const mediaHotahika = document.querySelector('.mediaHotahika')
  //   mediaHotahika.addEventListener('mouseover', () => {
  //     mediaHotahika.play()
  //   })
  //   mediaHotahika.addEventListener('mouseout', () => {
  //     mediaHotahika.load()
  //   })
  // })

  return (
    <div id="projects" className={`projects intersection ${styles.projects}`}>
      <h2 className="heading container">Projects</h2>
      <div className={styles.projects__container}>
        <div className={`section container ${styles.item} ${styles.item__1}`}>
          <ProjectItem
            styles={styles}
            hero={imageMisawayaMain}
            description="Preservation project of an attractive Japanese house for future generations"
            slug="misawaya"
            title="Misawaya"
            role="Branding / Planning / Organizing / Marketing / Design / Web Development / Content Creation"
            url="https://misawaya.org/en/"
            content={
              <StyledModalContent>
                <div className="text lead container-sm itemFadein">
                  Misawaya is a project to preserve a traditional Japanese house
                  that is built in 1861. With the attractive building and
                  precious architecture, people hope to keep the house, and we
                  have decided to take over the preservation. I have been
                  organizing this project since 2016 as a co-owner.
                </div>
                <Img
                  fluid={imageMisawayaMain}
                  alt="Misawaya"
                  className="image__main block itemFadein"
                />
                <div className="container-sm block itemFadein">
                  <h3 className="heading font-serif">The Story</h3>
                  <div className="text">
                    <p>
                      No one has lived at Misawaya for last 40 years. However,
                      neighbors thought that the house was very important for
                      the town of and that they should preserve it for future
                      generations. And then, they began taking care of the house
                      for the last 20 years.{' '}
                    </p>
                    <p>
                      In 2014, they told my family that it was going to be
                      difficult for them to continue to take care of the house
                      because they were too old. The owner of the house was a
                      distant family relative, and we found out that we had to
                      do something to help.
                      <br />
                      Originally, we had planned to tear the house down. The
                      more we get to know about Misawaya, however, the more we
                      realized we couldn’t do that. We were entranced by the
                      house’s 150 years history of history and the number of
                      people whose lives it had shaped. And we were inspired by
                      all the volunteers who had worked to sustain it.
                    </p>
                    <p>
                      My family members and I thought long and hard about what
                      we could do for the house. In 2016, finally, I decided I
                      was going to work as the organizer. Then our family
                      started to renovate the house and ensure the future of
                      this valuable cultural resource.
                    </p>
                  </div>
                </div>
                <div className="container-fluid block itemFadein">
                  <Img
                    fluid={imageMisawayaSub1}
                    alt=""
                    className="image__sub"
                  />
                </div>
                <div className="container-sm block itemFadein">
                  <h3 className="heading font-serif">
                    Preservation issues and solution
                  </h3>
                  <div className="text">
                    There are two things we need that I can think of for the
                    preservation of the house. One is fundraising, and the other
                    is people who can work for. To get them, I felt that the
                    house has to be a well-known place and people gather to
                    enjoy the place. As a solution, I have started to try new
                    projects such as real events, summer cafe, food product
                    development, etc.
                  </div>
                </div>
                <div className="container-fluid block itemFadein">
                  <Img
                    fluid={imageMisawayaSub2}
                    alt=""
                    className="image__sub"
                  />
                </div>
                <div className="container-sm block itemFadein">
                  <h3 className="heading font-serif">
                    Achievement and next step
                  </h3>
                  <div className="text">
                    Improving the digital appearance, and growing social
                    networking in the local community for 3 years with utilizing
                    my digital skills and project management experiences, it
                    ended up bringing 300 people to our last summer event. As a
                    next step, we have started building an online community app
                    that our members can share information and enjoy
                    communication online.
                  </div>
                </div>
                <div className="container-fluid itemFadein">
                  <Img
                    fluid={imageMisawayaSub3}
                    alt="Misawaya Websites"
                    className="image__sample"
                  />
                </div>
              </StyledModalContent>
            }
          />
        </div>

        <div className={`section container ${styles.item} ${styles.item__2}`}>
          <ProjectItem
            styles={styles}
            hero={imageEYMain}
            description="A documentary film director multi-language website"
            slug="ey"
            title="Ema Ryan Yamazaki"
            role="Design / Development / Multi-Language / SEO"
            url="https://www.emaexplorations.com/en/"
            content={
              <StyledModalContent>
                <div className="text lead container-sm itemFadein">
                  Ema Ryan Yamazaki is a documentary film director based between
                  New York and Tokyo. She has a unique perspective as an insider
                  and outsider in both Japan and the US and she is actively
                  working in both countries to produce films with her styles.
                </div>
                <Img
                  fluid={imageEYMain}
                  alt="Ema Ryan Yamazaki"
                  className="image__main image_initial block itemFadein"
                />
                <div className="container-sm block itemFadein">
                  <h3 className="heading font-serif">
                    Simple, clear, easy to find
                  </h3>
                  <div className="text">
                    <p>
                      Her main request is making both Japanese and English pages
                      presented evenly for her various viewers between Japan and
                      the US. Besides, the important things as a film director
                      website is easy to find, understand her specialty and past
                      works after people watched their works.
                    </p>
                    <p>
                      What I've done is making a structure to understand her
                      specialty at a glance, then can see her work as a natural
                      flow. Also as a multi-language website, I optimized search
                      engine friendly for each language.
                    </p>
                  </div>
                </div>
                <div className="container-fluid block itemFadein">
                  <Img
                    fluid={imageEYSub}
                    alt=""
                    className="image__sub image_initial"
                  />
                </div>
              </StyledModalContent>
            }
          />
        </div>

        {/* <div className={`section container ${styles.item} ${styles.item__3}`}>
          <ProjectItem
            styles={styles}
            hero={imageCinericMain}
            description="An independent film production company's website"
            slug="cineric"
            title="Cineric Creative"
            role="Design / Development"
            url="https://cinericcreative.com/en/"
            content={
              <StyledModalContent>
                <div className="text lead container-sm itemFadein">
                  Cineric Creative is an independent production and
                  post-services company based in New York and Tokyo. It is
                  especially focused on international co-productions with Japan,
                  where it has a strong reputation for helping to realize
                  ambitious art-house films.
                </div>
                <Img
                  fluid={imageCinericMain}
                  alt="Cineric Creative"
                  className="image__main block itemFadein"
                />
                <div className="container-sm block itemFadein">
                  <h3 className="heading font-serif">
                    Website as a global company
                  </h3>
                  <div className="text">
                    Created an English website as a global company that produces
                    many international films with Japan to expand the business
                    to make attention of people from all over the world.
                  </div>
                </div>
                <div className="container-fluid block itemFadein">
                  <Img
                    fluid={imageCinericSub}
                    alt=""
                    className="image__sub image_initial"
                  />
                </div>
              </StyledModalContent>
            }
          />
        </div> */}

        <div className={`section container ${styles.item} ${styles.item__4}`}>
          <ProjectItem
            styles={styles}
            hero={imageNamingMain}
            description="Web App for post-production companies"
            slug="naming"
            title="Post Production App"
            role="Web App Development"
            url="https://postproduction.app/"
            content={
              <StyledModalContent>
                <div className="text lead container-sm itemFadein">
                  A web application for post-production companies in the film
                  industry. The app allows users to generate a deliverable file
                  name which is very complicated to make manually.
                </div>
                <Img
                  fluid={imageNamingMain}
                  alt="Naming Convention"
                  className="image__main block itemFadein"
                />
                <div className="container-sm block itemFadein">
                  <h3 className="heading font-serif">
                    Contribution as a developer
                  </h3>
                  <div className="text">
                    The idea came from a filmmaker of my friends. He had been
                    straggle with making file names to deliver the film because
                    of its complexity. However, the logic as a web application
                    is pretty simple, so I developed the application as a
                    contribution to filmmakers.
                  </div>
                </div>
                <div className="container-fluid block itemFadein">
                  <Img fluid={imageNamingSub} alt="" className="image__sub" />
                </div>
              </StyledModalContent>
            }
          />
        </div>

        {/* <div className={`section container ${styles.item} ${styles.item__5}`}>
          <ProjectItem
            styles={styles}
            hero={imageMorusMain}
            description="Preserve a community and historical archives in Lower East Side"
            slug="morus"
            title="MoRUS"
            role="Tech Volunteer"
            url="http://www.morusnyc.org/"
            content={
              <StyledModalContent>
                <div className="text lead container-sm itemFadein">
                  the Museum of Reclaimed Urban Space is a small museum in Lower
                  East Side. The Museum has archives about squats and community
                  gardens which is very interesting.
                </div>
                <Img
                  fluid={imageMorusMain}
                  alt="Naming Convention"
                  className="image__main block itemFadein"
                />
                <div className="container-sm block itemFadein">
                  <h3 className="heading font-serif">
                    Help to preserve the local community as a developer
                  </h3>
                  <div className="text">
                    After I moved to NYC, I had searched how I can connect to
                    the local community and found this museum luckily. Then I
                    started to support the museum as a web developer.
                  </div>
                </div>
                <div className="container-fluid block itemFadein">
                  <Img fluid={imageMorusSub} alt="" className="image__sub" />
                </div>
              </StyledModalContent>
            }
          />
        </div> */}

        {/* <div className={styles.row_container}>
          <div className={`section container ${styles.item} ${styles.item__6}`}>
            <div className={`${styles.item__inner}`}>
              <video
                loop
                muted
                className={`mediaHineru fadein ${styles.item__video}`}
                poster={require(`./../../assets/images/projects-hineru.jpg`)}
              >
                <source
                  src={require(`./../../assets/video/projects-hineru.mp4`)}
                  type="video/mp4"
                />
                <source
                  src={require(`./../../assets/video/projects-hineru.webm`)}
                  type="video/webm"
                />
              </video>
            </div>
          </div>

          <div className={`section container ${styles.item} ${styles.item__7}`}>
            <div className={`${styles.item__inner}`}>
              <video
                loop
                muted
                className={`mediaFS fadein ${styles.item__video}`}
                poster={require(`./../../assets/images/projects-fs.jpg`)}
              >
                <source
                  src={require(`./../../assets/video/projects-fs.mp4`)}
                  type="video/mp4"
                />
                <source
                  src={require(`./../../assets/video/projects-fs.webm`)}
                  type="video/webm"
                />
              </video>
            </div>
          </div>

          <div className={`section container ${styles.item} ${styles.item__8}`}>
            <div className={`${styles.item__inner}`}>
              <video
                loop
                muted
                className={`mediaHotahika fadein ${styles.item__video}`}
                poster={require(`./../../assets/images/projects-hotahika.jpg`)}
              >
                <source
                  src={require(`./../../assets/video/projects-hotahika.mp4`)}
                  type="video/mp4"
                />
                <source
                  src={require(`./../../assets/video/projects-hotahika.webm`)}
                  type="video/webm"
                />
              </video>
            </div>
          </div>

          <div className={`section container ${styles.item}`}>
            <div className={`${styles.item__inner}`}>
              <Img
                fluid={imageParallax}
                alt=""
                className="image__main block fadein"
              />
            </div>
          </div>

          <div className={`section container ${styles.item}`}>
            <div className={`${styles.item__inner}`}>
              <Img
                fluid={imageCineric}
                alt=""
                className="image__main block fadein"
              />
            </div>
          </div>

          <div className={`section container ${styles.item}`}>
            <div className={`${styles.item__inner}`}>
              <Img
                fluid={imageFather}
                alt=""
                className="image__main block fadein"
              />
            </div>
          </div>

          <div className={`section container ${styles.item}`}>
            <div className={`${styles.item__inner}`}>
              <Img
                fluid={imageTempura}
                alt=""
                className="image__main block fadein"
              />
            </div>
          </div>
          <div className={`section container ${styles.item}`}>
            <div className={`${styles.item__inner}`}>
              <Img
                fluid={imageGRF}
                alt=""
                className="image__main block fadein"
              />
            </div>
          </div>
          <div className={`section container ${styles.item}`}>
            <div className={`${styles.item__inner}`}>
              <Img
                fluid={imageCinericJP}
                alt=""
                className="image__main block fadein"
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}
