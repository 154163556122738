import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import styles from './About.module.scss'

gsap.registerPlugin(ScrollTrigger)

export default () => {
  const data = useStaticQuery(graphql`
    query {
      imageAbout: file(relativePath: { eq: "about-main.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <div id="about" className={`about intersection ${styles.about}`}>
      <h2 className="heading container-lg">About</h2>
      <div className={`container-lg section ${styles.about__content}`}>
        <Img
          fluid={data.imageAbout.childImageSharp.fluid}
          alt="Ayana"
          className={`fadein ${styles.image}`}
        />

        <div className={`fadein ${styles.description}`}>
          <div>
            <p>
              <span className={styles.name}>Ayana Osawa</span> is a front-end
              designer with 14+ years of experience in design, development, and
              organizing/managing projects based in New York and Nagano, Japan.
              The strong UI/UX skills with technical knowledge and deep
              understanding of entire businesses bring problem-solving to
              companies’ services and products.
            </p>

            <h3>What I do</h3>
            <p style={{ marginBottom: '0.5rem' }}>
              <b>Creative Dev/Design</b> - Front-end creative development for
              websites/web apps focusing on functional UI using HTML/CSS,
              JavaScript, React/Gatsby.
            </p>
            <p>
              <b>Digital Marketing Consulting</b> - Digital marketing/e-commerce
              consultation and technical advisory for small companies and
              owners.
            </p>

            <h3>Specialty</h3>
            <ul className={styles.description__specialty}>
              <li>UI/UX Design</li>
              <li>Responsive/Interactive Coding</li>
              <li>E-commerce Design</li>
              {/* <li>Internationalization</li> */}
            </ul>

            <h3>Skills/Tools</h3>
            <ul className={styles.description__tools}>
              <li>HTML/CSS</li>
              <li>SCSS</li>
              <li>UI/UX</li>
              <li>JavaScript</li>
              <li>ReactJS</li>
              <li>Gatsby</li>
              <li>SEO</li>
              <li>GSAP</li>
              <li>three.js</li>
              <li>TypeScript</li>
              <li>Git</li>
              <li>Webpack/Gulp</li>
              <li>WordPress</li>
              <li>Photoshop</li>
              <li>Illustrator</li>
              <li>XD</li>
              <li>Figma</li>
              {/* <li>Firebase</li>
              <li>Netlify</li>
              <li>Docker</li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
