import React from 'react'
import Hero from '../components/Hero/Hero'
import Projects from '../components/Projects/Projects'
import About from '../components/About/About'

import Layout from '../components/Layout/Layout'

const IndexPage = () => {
  const pageSlug = 'home'
  return (
    <Layout pageSlug={pageSlug}>
      <Hero />
      <Projects />
      <About />
    </Layout>
  )
}

export default IndexPage
