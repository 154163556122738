import React from 'react'
import styles from './Modal.module.scss'
import gsap from 'gsap'

const Modal = ({ title, role, content, setIsModal, slug, url }) => {
  const tl = gsap.timeline()

  const handleClose = () => {
    tl.to('.itemFadein', {
      opacity: 0,
      y: '30',
      duration: 0.4,
      ease: 'power1.inOut',
    }).to('.modal__inner', {
      x: '-100%',
      delay: 0.2,
      duration: 0.8,
      ease: 'power1.inOut',
    })

    setTimeout(() => {
      document.body.style.removeProperty('padding-right')
      document.body.classList.remove('lock')
      setIsModal(false)
    }, 1500)
  }
  function preventClose(e) {
    e.stopPropagation()
  }

  return (
    <>
      {/* eslint-disable-next-line */}
      <div
        className={`modal__overlay ${styles.modal__overlay} ${
          slug ? slug : ''
        }`}
      >
        {/* eslint-disable-next-line */}
        <div
          className={`modal__inner ${styles.modal__inner}`}
          onClick={preventClose}
          onKeyDown={preventClose}
        >
          <div className="modal__content">
            <h2 className={`font-serif itemFadein ${styles.modal__header}`}>
              {title}
            </h2>
            <p className={`container itemFadein ${styles.modal__role}`}>
              {role}
            </p>
            <div>{content}</div>
            <div className="itemFadein">
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className={` ${styles.button__visit}`}
              >
                Visit Site
              </a>
            </div>
            <button
              onClick={handleClose}
              onKeyDown={handleClose}
              className={`itemFadein ${styles.close__fixed}`}
              tabIndex={-1}
            >
              <span className={styles.close__fixed__text}>×</span>
            </button>
            <button
              onClick={handleClose}
              onKeyDown={handleClose}
              className={`itemFadein ${styles.close__button}`}
              tabIndex={-1}
            >
              <span className={styles.close__button__icon}>←</span>
              <span className={styles.close__button__text}>back</span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal
